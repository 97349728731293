import React from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import Stars from './stars';
import Clouds from './clouds';
import Sun from './sun';

const Background = () => {
  return (
    <StyledBackground
      variants={{
        unchecked: {
          background: 'linear-gradient(270deg, #656EF3 0%, #4B69AB 47%)'
        },
        checked: {
          background: 'linear-gradient(-10deg, #242864 6%, #070F21 99%)'
        }
      }}
      transition={{ duration: 0.2 }}
    >
      <StyledContainer>
        <Stars />
        <Sun />
        <Clouds />
      </StyledContainer>
    </StyledBackground>
  );
};

export default Background;

const StyledBackground = styled(motion.div)`
  width: 100%;
  top: 2px;
  bottom: 2px;
  left: 0;

  position: absolute;

  z-index: -1;
  border-radius: 20px;
`;

const StyledContainer = styled(motion.div)`
  position: relative;
  width: 100%;
  height: 100%;
`;
