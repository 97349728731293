import { createGlobalStyle } from 'styled-components';
import reset from 'styled-reset';
import { gridQuery } from './theme/helpers/layout';
import './assets/fonts/nunito/index.css';

export const GlobalStyle = createGlobalStyle`
    ${reset}
    
    
    html,
    body {

    }
    
    html {
      box-sizing: border-box;
    }
    
    *,
    *:before,
    *:after {
      box-sizing: inherit;
    }
    
    body {
      margin: 0;
      font-family: 'Nunito', sans-serif;
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
      
      background: #581f9d;
    }
    
    code {
      font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
    }
    
    #root {
      //perspective: 1000px;
      
      display: flex;
      flex-direction: column;
      min-height: 100%;
      overflow: hidden;
      width: 100%;
      ${gridQuery}
    }
`;
