const prices = {
  pages: [
    { start: 1, end: 1, price: 1500, perPage: true },
    { start: 2, end: 10, price: 800, perPage: true },
    { start: 11, end: Infinity, price: 'custom', perPage: true }
  ],
  seo: [
    { start: 1, end: 5, price: 500, perPage: true },
    { start: 6, end: 10, price: 300, perPage: true },
    { start: 11, end: Infinity, price: 'custom', perPage: true }
  ],
  copyWriting: [
    { start: 1, end: 5, price: 200, perPage: true },
    { start: 6, end: 10, price: 300, perPage: true },
    { start: 11, end: Infinity, price: 'custom', perPage: true }
  ],
  customForms: [{ start: 1, end: Infinity, price: 200, perPage: false }],
  eCommerce: [{ start: 1, end: Infinity, price: 'custom', perPage: false }]
};

const getPriceByQuantity = (key, quantity) => {
  const pricingTable = prices[key];

  if (typeof pricingTable === 'undefined') {
    return { price: 'custom', total: 'custom' };
  }

  for (const { start, end, price, perPage } of prices[key]) {
    if (start <= quantity && end >= quantity) {
      let total = 'custom';
      if (price !== 'custom') {
        total = !perPage ? price : price * quantity;
      }
      return { price, total };
    }
  }

  return { price: 'custom', total: 'custom' };
};

const getPricing = fields => {
  let result = { total: 0 };

  let custom = false;

  const { pages } = fields;

  for (const key in fields) {
    if (fields.hasOwnProperty(key)) {
      const field = fields[key];

      if (!field) {
        result[key] = { price: 0, total: 0 };
        continue;
      }

      if (prices[key] === undefined) {
        continue;
      }

      const { price, total } = getPriceByQuantity(key, pages);

      if (price === 'custom') {
        custom = true;
        result.total = 'custom';
        result[key] = { price: 'custom', total: 'custom' };
      } else {
        result[key] = { price, total };
      }

      if (!custom) {
        result.total += total;
      }
    }
  }

  return result;
};

export default getPricing;

// Number of web pages
// Min: 1 - Max 10 ( slider )
// Price: $1500(1) $800pp ( 2 - 10 )  $Custom Quote ( 10+)
//
// Search Engine Optimization
// Yes/No
// Price: $500pp ( 1 - 5 )  $300pp ( 6 - 10)  $Custom Quote ( 10+)
//
// Copywriting
// Yes/No
// Price: $300pp ( 1 - 5 )  $200pp ( 6 - 10)  $Custom Quote ( 10+)
//
// Custom Forms
// Yes/No
// Price: $200
//
// E-Commerce Functionally
// Yes/No
// $Custom Quote
