import Option from './option';
import { motion } from 'framer-motion';
import React from 'react';
import Range from '../../../../theme/form/range';
import Toggle from '../../../../theme/form/toggle';
import styled from 'styled-components';
import { grid, layout } from '../../../../theme/helpers/layout';
import getPricing from './prices';

const fields = [
  { name: 'pages', label: 'Number of Pages', fullWidth: true, Control: Range },
  { name: 'seo', label: 'Search Engine Optimization', Control: Toggle },
  { name: 'copyWriting', label: 'Copy Writing', Control: Toggle },
  { name: 'customForms', label: 'Custom Forms', Control: Toggle },
  { name: 'eCommerce', label: 'E-Commerce Functionality', Control: Toggle }
];

const Options = ({ showControls, values, onChange, spring }) => {
  const pricing = getPricing(values);

  const getPriceForField = name => {
    if (!showControls) {
      return name === 'pages' ? values.pages : 'yes';
    }

    return pricing[name].total === 'custom'
      ? 'Custom'
      : '$' + pricing[name].total;
  };

  return (
    <StyledOptions layoutTransition={spring}>
      {fields.map(field =>
        showControls || values[field.name] ? (
          <Option
            key={field.name}
            {...field}
            onChange={onChange}
            value={values[field.name]}
            price={getPriceForField(field.name)}
            showControl={showControls}
          />
        ) : null
      )}
      <motion.hr />
      <Option
        label="Estimated Total"
        price={pricing.total === 'custom' ? 'Custom' : '$' + pricing.total}
      />
    </StyledOptions>
  );
};

export default Options;

const StyledOptions = styled(motion.div)`
  width: 100%;

  grid-row-gap: inherit;
  grid-auto-rows: min-content;
  ${layout()}
  ${grid}
`;
