import React from 'react';
import { hydrate, render } from 'react-dom';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter as Router } from 'react-router-dom';

import { GlobalStyle } from './app/styles';

import App from './app';

const rootElement = document.getElementById('root');

const Root = () => (
  <>
    <GlobalStyle />
    <Router>
      <App />
    </Router>
  </>
);

if (rootElement.hasChildNodes()) {
  hydrate(<Root />, rootElement);
} else {
  render(<Root />, rootElement);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
