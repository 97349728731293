import { useLocation } from 'react-router-dom';
import { useEffect } from 'react';

const useRouterScroll = () => {
  const { hash, key } = useLocation();

  useEffect(() => {
    if (hash !== '') {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth', block: 'end' });
      }
    }
  }, [hash, key]);
};

export default useRouterScroll;
