import React from 'react';
import ReactDOM from 'react-dom';

import styled from 'styled-components';
import colors from '../../../theme/helpers/colors';
import MenuItem from './item';
import useGoto from '../../../hooks/router_goto';
import Times from '../../../theme/icons/assets/regular/times';
import { grid, layout } from '../../../theme/helpers/layout';
import { motion } from 'framer-motion';
import ColorSchemeToggle from '../color_scheme_toggle';

const Menu = ({ isOpen, onClose }) => {
  const goto = useGoto(onClose);

  return ReactDOM.createPortal(
    <>
      <StyledMenu
        isOpen={isOpen}
        initial={{ x: '100%' }}
        animate={{ x: isOpen ? '0%' : '100%' }}
        transition={{
          type: 'spring',
          stiffness: 300,
          damping: 30
        }}
        aria-expanded={isOpen}
      >
        <StyledCloseButton onClick={onClose}>
          <Times />
        </StyledCloseButton>
        <MenuItem onClick={goto('#home')}>Home</MenuItem>
        <MenuItem onClick={goto('#services')}>Services</MenuItem>
        <MenuItem onClick={goto('#process')}>Process</MenuItem>
        <MenuItem onClick={goto('#about')}>About Us</MenuItem>
        <MenuItem onClick={goto('#pricingTool')}>Estimate Tool</MenuItem>
        <ColorSchemeToggle />
      </StyledMenu>
      <StyledOverlay isOpen={isOpen} onClick={onClose} aria-hidden />
    </>,
    document.getElementById('root')
  );
};

export default Menu;

const StyledMenu = styled(motion.nav)`
  position: fixed;
  height: 100vh;
  width: 100vw;
  top: 0;
  right: 0;

  z-index: 1000;

  padding: 24px 0;

  background: linear-gradient(
    180deg,
    ${colors.gradientLight} 0%,
    ${colors.gradientDark} 100%
  );
  color: ${colors.onPrimary};

  & > * {
    ${layout()}
  }

  justify-items: center;
  grid-auto-rows: min-content;
  grid-row-gap: 24px;
  ${grid}
`;

const StyledCloseButton = styled.div`
  width: 48px;
  height: 48px;

  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 36px;
    height: 36px;
    fill: #ffffff;
  }

  justify-self: flex-end;
`;

const StyledOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;

  width: 100vw;
  height: 100vh;
  z-index: 999;

  background: #000000;

  pointer-events: ${props => (props.isOpen ? 'unset' : 'none')};

  opacity: ${props => (props.isOpen ? 0.4 : 0)};

  transition: opacity 100ms ease-out;
`;
