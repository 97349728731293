import styled from 'styled-components';
import colors from '../helpers/colors';
import { motion } from 'framer-motion';
import { columnQuery } from '../helpers/layout';

const StyledButton = styled(motion.button)`
  background: ${colors.secondary};
  color: ${colors.onSecondary};
  font-size: 14px;
  font-weight: bold;
  border: unset;
  border-radius: 2em;
  padding: 8px;

  align-self: center;

  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 48px;

  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);

  @media ${columnQuery(12)} {
    font-size: 16px;
  }

  outline: none;
`;

export default StyledButton;
