import React from 'react';
import styled from 'styled-components';
import { grid } from '../helpers/layout';
import colors from '../helpers/colors';

const Card = ({ as, icon, title, children }) => {
  return (
    <StyledCard as={as}>
      <StyledIcon color1={colors.primaryLight} color2={colors.primary}>
        {icon}
      </StyledIcon>
      <StyledContent>
        <StyledTitle>{title}</StyledTitle>
        <StyledBody>{children}</StyledBody>
      </StyledContent>
    </StyledCard>
  );
};

export default Card;

export const StyledCard = styled.div`
  ${grid}
`;

export const StyledIcon = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  background: ${colors.surface};

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 14px;
  padding-top: 100%;
  height: 0;

  & > * {
    margin-top: -100%;
  }

  svg {
    height: 36px;
    path {
      &:first-child {
        fill: ${colors.primaryLight};
      }
      &:nth-child(2) {
        fill: ${colors.primary};
      }
    }
  }
`;

export const StyledContent = styled.div`
  grid-column: 2 / -1;
`;

export const StyledTitle = styled.h3`
  font-size: 16px;
  line-height: 1.5em;
  font-weight: bold;
  margin-bottom: 6px;

  color: ${colors.onSurface};
`;
export const StyledBody = styled.div`
  font-size: 14px;
  font-weight: bold;
  line-height: 1.5em;

  color: ${colors.onSurface};
`;
