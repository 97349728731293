import React from 'react';
import styled from 'styled-components';

const Body = ({ children }) => {
  return <StyledBody>{children}</StyledBody>;
};

export default Body;

const StyledBody = styled.main`
  margin-top: 96px;
`;
