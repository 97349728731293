import styled from 'styled-components';
import { motion } from 'framer-motion';

const StyledIcon = styled(motion.div)`
  svg {
    width: ${props => props.width || '100%'};
    height: ${props => props.height || '100%'};

    path {
      &:first-child {
        fill: ${props => props.color1};
      }
      &:nth-child(2) {
        fill: ${props => props.color2};
      }
    }
  }
`;
export default StyledIcon;
