import React from 'react';
import styled, { css } from 'styled-components';
import colors from '../../helpers/colors';

const Range = ({ min = 1, max = 10, ...props }) => {
  return (
    <StyledRange>
      <input type="range" min={min} max={max} {...props} />
      <StyledBubble min={min} max={max} value={props.value}>
        <div>{props.value}</div>
      </StyledBubble>
      <BackgroundTicks range={max - min} />
    </StyledRange>
  );
};

export default Range;

const thumbStyle = css`
  -webkit-appearance: none;
  height: var(--thumbHeight);
  width: var(--thumbWidth);
  cursor: pointer;
  background: #d9219f;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border: 0;
  border-radius: 4px;

  margin-top: -10px;
`;

const trackStyle = css`
  width: 100%;
  height: 4px;
  cursor: pointer;

  border-color: transparent;
  background: #ffffff;
  border-radius: 2px;
`;

const focusStyle = css``;

const StyledRange = styled.div`
  position: relative;
  height: 56px;

  --thumbWidth: 12px;
  --thumbHeight: 24px;

  z-index: 2;

  & > input {
    appearance: none;
    background: transparent;
    border: none;

    width: 100%;
    height: 4px;

    border: 0;
    margin: 42px 0 0 0;

    &:focus {
      outline: none;
    }

    &::-webkit-slider-thumb {
      ${thumbStyle}
    }

    &::-moz-range-thumb {
      ${thumbStyle}
    }
    &::-ms-thumb {
      ${thumbStyle}
    }

    &::-webkit-slider-runnable-track {
      ${trackStyle}
    }

    &:focus::-webkit-slider-runnable-track {
      ${focusStyle}
    }

    &::-moz-range-track {
      ${trackStyle}
    }

    &::-ms-track {
      ${trackStyle}
    }
    &::-ms-fill-lower {
      ${trackStyle}
    }
    &:focus::-ms-fill-lower {
      ${focusStyle}
    }
    &::-ms-fill-upper {
      ${trackStyle}
    }
  }
`;

const StyledBubble = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  pointer-events: none;
  color: black;

  height: 30px;
  display: inline-block;

  transform: translateX(
    calc(
      100% * ${props => (props.value - 1) / (props.max - 1)} -
        ${props => (props.value - 1) / (props.max - 1)} * var(--thumbWidth)
    )
  );

  & > * {
    width: 40px;
    height: 22px;
    background: #ffffff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-left: -12px;
    transform: translateX(calc(-50% + var(--thumbWidth) / 2));
    color: ${colors.primary};
    font-weight: bold;
    font-size: 14px;

    &::after {
      content: '';
      position: absolute;
      bottom: -4px;

      border-width: 4px 4px 0;
      border-style: solid;
      border-color: #ffffff transparent;
      display: block;
      width: 0;
    }
  }
`;

const BackgroundTicks = ({ range }) => {
  const ticks = [];

  for (let i = 0; i < range + 1; i++) {
    ticks.push(
      <StyledBackgroundTick key={i} viewBox="0 0 96 448">
        <path d="M96 416V32C96 14 82 0 64 0H32C14 0 0 14 0 32v384c0 18 14 32 32 32h32c18 0 32-14 32-32z" />
      </StyledBackgroundTick>
    );
  }

  return <StyledTickContainer range={range}>{ticks}</StyledTickContainer>;
};

const StyledTickContainer = styled.div`
  left: 0;
  right: 0;

  bottom: 0;

  padding: 0 calc(var(--thumbWidth) * 0.25);

  z-index: -1;
  height: var(--thumbHeight);

  position: absolute;
  display: flex;
  justify-content: space-between;
`;

const StyledBackgroundTick = styled.svg`
  opacity: 0.3;
  //transform: translateX(-50%);

  height: 100%;
  width: 4px;
`;
