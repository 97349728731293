import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const Price = ({ children }) => {
  return <StyledPrice>{children}</StyledPrice>;
};

export default Price;

const StyledPrice = styled(motion.div)`
  grid-column: -2;
  padding-right: 22px;
  font-size: 14px;
  font-weight: bold;
`;
