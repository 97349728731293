import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const Slider = () => {
  return (
    <StyledSlider>
      <Sun />
      <Moon />
    </StyledSlider>
  );
};

export default Slider;

const StyledSlider = styled(motion.div)`
  background: #ffffff;

  border-radius: 50%;
  position: relative;

  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

  & > svg {
    position: absolute;
    padding: 5px;
    width: 100%;
    height: 100%;

    path {
      &:first-child {
        fill: #cf9dde;
      }
      &:nth-child(2) {
        fill: #8843b7;
      }
    }
  }
`;

const Moon = () => (
  <motion.svg
    viewBox="0 0 512 512"
    variants={{
      unchecked: { rotate: '45deg', opacity: 0 },
      checked: { rotate: '0deg', opacity: 1 }
    }}
    style={{ originX: '50%', originY: '50%' }}
    transition={{ duration: 0.2 }}
  >
    <path d="M320 32L304 0l-16 32-32 16 32 16 16 32 16-32 32-16zm139 149l-27-53-27 53-53 27 53 27 27 53 27-53 53-27z" />
    <path d="M332 426c8-1 14 8 9 15a191 191 0 01-149 71 192 192 0 1135-381c8 2 10 13 3 17a150 150 0 00-76 131c0 94 85 165 178 147z" />
  </motion.svg>
);

const Sun = () => (
  <motion.svg
    viewBox="0 0 512 512"
    variants={{
      unchecked: { rotate: '0deg', opacity: 1 },
      checked: { rotate: '45deg', opacity: 0 }
    }}
    style={{ originX: '50%', originY: '50%' }}
    transition={{ duration: 0.2 }}
  >
    <path d="M502 241l-94-48 33-100c5-14-8-27-22-22l-100 33-47-94a17 17 0 00-31 0l-48 94L93 71c-14-5-27 8-22 22l33 100-94 47a17 17 0 000 32l94 47-33 100c-5 14 8 27 22 22l100-33 47 94a17 17 0 0031 0l48-94 100 33c14 5 27-8 22-22l-33-100 94-47a17 17 0 000-32zM347 347c-50 49-132 49-181 0a128 128 0 010-181c49-50 131-50 181 0a128 128 0 010 181z" />
    <path d="M352 256a96 96 0 11-96-96 96 96 0 0196 96z" />
  </motion.svg>
);
