import { css } from 'styled-components';
import breakpoints from './breakpoints';

export const columnQuery = numberOfColumns => {
  const { min, max } = breakpoints.get(parseInt(numberOfColumns));

  const query = [];

  if (min !== undefined) {
    query.push(`(min-width: ${min}px)`);
  }

  if (max !== undefined) {
    query.push(`(max-width: ${max}px)`);
  }

  return query.join(' and ');
};

// creates media queries that set CSS variables to the grid properties
const generateGridQuery = () => {
  let styling = '';

  for (const [, { columns, marginSize, gutterSize }] of breakpoints) {
    styling += `@media ${columnQuery(columns)} {        
                    --gridMarginSize: ${marginSize}px;
                    --gridColumns: ${columns};
                    --gridGutterSize: ${gutterSize}px;
                    --gridCenterColumn: ${Math.floor(columns / 2)}
                }`;
  }
  return styling;
};

export const gridQuery = generateGridQuery();

const negateGutter = columns => {
  const gutterMargin = `calc(-${columns - 1} * var(--gridGutterSize))`;
  return `margin-left: ${gutterMargin}; margin-right: ${gutterMargin};`;
};

export const span = (
  span = 'fill',
  justify = 'center',
  ignoreGutter = false
) => columns => {
  let query = '';
  let column = '';

  if (span === 'fill') {
    column = '1 / -1';
  } else {
    const areaCenter = Math.floor(span / 2);

    if (justify === 'center') {
      column = `calc(var(--gridCenterColumn) - ${areaCenter +
        -1}) / span ${span}`;
    } else if (justify === 'right') {
      column = `calc(-1 - ${span}) / span ${span}`;
    } else if (justify === 'left') {
      column = `1 / span ${span}`;
    } else if (justify === 'none') {
      column = `span ${span}`;
    }

    query += `& > * {--gridCenterColumn: ${areaCenter};}`;
  }

  query += `grid-column: ${column};`;

  if (span !== 'fill') {
    query += `--gridColumns: ${span};`;

    if (ignoreGutter) {
      query += negateGutter(span);
    }
  }

  query += '--gridMarginSize: 0;';

  return `@media ${columnQuery(columns)}  { ${query} }`;
};

export const layout = config => {
  config = {
    any: span(),
    ...config
  };

  let styling = '';

  for (const [, { columns }] of breakpoints) {
    const layoutFunction = config[columns] || config.any;
    styling += layoutFunction(columns);
  }

  return styling;
};

export const grid = css`
  display: grid;
  grid-template-columns: repeat(var(--gridColumns), minmax(0, 1fr));
  grid-column-gap: var(--gridGutterSize);
  column-gap: var(--gridGutterSize);
  padding-left: var(--gridMarginSize);
  padding-right: var(--gridMarginSize);
`;
