import React from 'react';
import styled from 'styled-components';
import Section from '../../../theme/section';
import colors from '../../../theme/helpers/colors';
import { columnQuery, layout, span } from '../../../theme/helpers/layout';
import StyledButton from '../../../theme/buttons';
import Background from '../../../theme/backgrounds';
import useGoto from '../../../hooks/router_goto';
import Title, { titleSize } from '../../../theme/typography/title';

const Banner = () => {
  const goto = useGoto();

  return (
    <StyledSection background={<Background />}>
      <Title size={titleSize.medium} as="p">
        Get An Instant Estimate Today!
      </Title>
      <StyledButton onClick={goto('#pricingTool')}>Get Estimate</StyledButton>
    </StyledSection>
  );
};

export default Banner;

const StyledSection = styled(Section)`
  position: relative;
  padding-top: 56px;
  padding-bottom: 56px;
  color: ${colors.onPrimary};

  grid-auto-rows: min-content;

  & > ${Title} {
    text-align: center;
    font-size: 24px;

    @media ${columnQuery(12)} {
      font-size: 32px;
    }
  }

  & > button {
    ${layout({ any: span(2, 'center', true), 12: span(4, 'center') })};
  }
`;
