const breakpoints = new Map([
  [
    4,
    {
      name: 'phone',
      min: 0,
      max: 599,
      gutterSize: 12,
      marginSize: 20,
      columns: 4,
      rowSize: 40
    }
  ],
  [
    6,
    {
      name: 'smallTablet',
      min: 600,
      max: 769,
      gutterSize: 16,
      marginSize: 24,
      columns: 6,
      rowSize: 40
    }
  ],
  [
    8,
    {
      name: 'largeTablet',
      min: 760,
      max: 959,
      gutterSize: 16,
      marginSize: 24,
      columns: 8,
      rowSize: 40
    }
  ],
  [
    12,
    {
      name: 'desktop',
      min: 960,
      gutterSize: 16,
      marginSize: 24,
      columns: 12,
      rowSize: 48
    }
  ]
]);
export default breakpoints;
