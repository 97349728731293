import { motion } from 'framer-motion';
import React from 'react';
import styled from 'styled-components';

const Label = ({ children }) => {
  return <StyledLabel>{children}</StyledLabel>;
};

export default Label;

const StyledLabel = styled(motion.div)`
  grid-row: 1;
  grid-column: 1 / -2;
  padding-left: 22px;
  font-size: 16px;
  font-weight: bold;
`;
