import React from 'react';
import Hero from './hero';
import Services from './services';
import Banner from './banner';
import Process from './process';
import About from './about';
import PricingTool from './pricing_tool';

const Home = () => {
  return (
    <>
      <Hero />
      <Services />
      <Banner />
      <Process />
      <About />

      <PricingTool />
    </>
  );
};

export default Home;
