import styled from 'styled-components';
import { motion } from 'framer-motion';
import React from 'react';

const Stars = props => (
  <StyledStars
    viewBox="0 0 512 512"
    variants={{
      checked: { opacity: 1 },
      unchecked: { opacity: 0 }
    }}
  >
    <path d="M416 80a7 7 0 00-4-6l-49-21-21-49a7 7 0 00-12 0l-21 49-49 21a7 7 0 000 12l49 21 21 49a7 7 0 0012 0l21-49 49-21a7 7 0 004-6zm93 139l-40-16-16-40a5 5 0 00-10 0l-16 40-40 16a5 5 0 000 10l40 16 16 40a5 5 0 0010 0l16-40 40-16a5 5 0 000-10z" />
    <path d="M364 267l-105-15-46-95c-9-17-33-17-42 0l-47 95-104 15c-19 3-27 26-13 39l76 74-18 105c-3 19 17 33 33 24l94-49 94 49a23 23 0 0033-24l-18-105 76-74c14-13 6-36-13-39z" />
  </StyledStars>
);

export default Stars;

const StyledStars = styled(motion.svg)`
  position: absolute;

  width: 12px;

  left: 12px;

  bottom: 50%;
  path {
    &:first-child {
      fill: #d6dd54;
      opacity: 40%;
    }
    &:nth-child(2) {
      fill: #d6dd54;
    }
  }
`;
