import React from 'react';
import styled from 'styled-components';
import { useAppContext } from '../../../context';
import Slider from './slider';
import Background from './background';
import ReactGA from 'react-ga';
import { motion } from 'framer-motion';

ReactGA.event({
  category: 'Pricing Tool',
  action: 'Clicked Next'
});
const ColorSchemeToggle = () => {
  const { scheme, toggleScheme } = useAppContext();

  const buttonSize = 36;

  return (
    <StyledToggle
      onClick={() => {
        ReactGA.event({
          category: 'App',
          action: 'Toggle Scheme'
        });

        toggleScheme();
      }}
      buttonSize={buttonSize}
      animate={scheme === 'dark' ? 'checked' : 'unchecked'}
      initial={'unchecked'}
      aria-hidden
    >
      <StyledSliderContainer
        variants={{ checked: { x: '100%' }, unchecked: { x: '0%' } }}
      >
        <Slider />
      </StyledSliderContainer>
      <Background />
    </StyledToggle>
  );
};

export default ColorSchemeToggle;

const StyledSliderContainer = styled(motion.div)``;

const StyledToggle = styled(motion.div)`
  height: ${props => props.buttonSize}px;
  user-select: none;

  width: 100%;
  max-width: 64px;

  position: relative;

  z-index: 1;

  & > ${StyledSliderContainer} {
    width: calc(100% - ${props => props.buttonSize}px);
    height: ${props => props.buttonSize}px;

    & > :first-child {
      width: ${props => props.buttonSize}px;
      height: ${props => props.buttonSize}px;
    }
  }
`;
