import React from 'react';

const RocketLaunch = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path d="M35.67945,352.06641C9.82789,377.91992-2.94945,442.59375.57789,511.41016c69.10156,3.55859,133.58984-9.35157,159.33984-35.10547,40.28516-40.2793,42.8711-93.98633,6.3086-130.54883C129.66773,309.19727,75.9607,311.78516,35.67945,352.06641Zm94.957,81.05468c-10.73046,10.73243-37.60546,16.11133-66.40234,14.627-1.46484-28.67383,3.85938-55.623,14.63281-66.39649,16.78125-16.78515,39.16407-17.86328,54.39844-2.6289C148.49586,393.959,147.42164,416.33789,130.63648,433.12109ZM51.94117,154.48438,2.531,253.29688A28.125,28.125,0,0,0-.00023,264a24.00619,24.00619,0,0,0,24,24H117.4607c23.44141-47.41211,61.01172-123.373,77.89063-157.32812.51953-.91407,1-1.76758,1.52344-2.67188H94.82008C78.47633,128.01562,59.28883,139.875,51.94117,154.48438ZM381.3357,316.57227c-33.96093,17.00781-110.0625,54.67968-157.33593,78.07421V488.209A24.12394,24.12394,0,0,0,247.9607,512a28.02965,28.02965,0,0,0,10.625-2.53125l98.72657-49.39063c14.625-7.3125,26.5-26.5,26.5-42.85937V315.70312c.0664-.041.125-.08789.1875-.1289v-.52734C383.09352,315.56641,382.25367,316.04883,381.3357,316.57227Z" />
    <path d="M505.15992,19.51562A16.73971,16.73971,0,0,0,492.62477,6.94531C460.22633,0,434.37477,0,409.48414,0,320.3357,0,252.80836,40.61523,196.97633,127.81836c-.5586.97852-1.07031,1.877-1.625,2.85352C178.47242,164.627,140.90211,240.58789,117.4607,288h10.53907a95.99975,95.99975,0,0,1,96,96v10.64648c47.27343-23.39453,123.375-61.0664,157.33593-78.07421.96094-.54883,1.832-1.04883,2.78907-1.59766,87.23437-56.06055,127.85937-123.51172,127.85937-212.27734C512.06227,77.60742,512.12867,52.08789,505.15992,19.51562ZM367.99977,192a48,48,0,1,1,48-48.00195A48.02156,48.02156,0,0,1,367.99977,192ZM78.867,381.35156C68.09352,392.125,62.7693,419.07422,64.23414,447.74805c28.79688,1.48437,55.67188-3.89453,66.40234-14.627,16.78516-16.7832,17.85938-39.16211,2.62891-54.39843C118.031,363.48828,95.6482,364.56641,78.867,381.35156Z" />
  </svg>
);

export default RocketLaunch;
