import React from 'react';
import { motion, useInvertedScale } from 'framer-motion';
import styled from 'styled-components';
import { grid, layout } from '../../../../theme/helpers/layout';

const Fields = ({ values, errors, onChange, spring }) => {
  const inputProps = {
    initial: { opacity: 0 },
    animate: { opacity: 1 },
    onChange
  };

  return (
    <StyledFields
      layoutTransition
      initial={{ scaleY: 0 }}
      animate={{ scaleY: 1 }}
      style={{ originY: '100%' }}
      transition={{ scaleY: spring }}
    >
      <Input
        type="text"
        name="name"
        {...inputProps}
        value={values.name}
        placeholder={'Enter Name'}
      />
      <Error error={errors.name} />

      <Input
        type="text"
        name="email"
        {...inputProps}
        value={values.email}
        placeholder={'Enter Email'}
      />
      <Error error={errors.email} />

      <TextArea
        name="message"
        {...inputProps}
        value={values.message}
        placeholder={'Any Additional Information?'}
      />
      <Error error={errors.message} />
    </StyledFields>
  );
};

export default Fields;

const Input = props => {
  const inverted = useInvertedScale();

  return (
    <StyledInput style={inverted} aria-label={props.placeholder} {...props} />
  );
};

const TextArea = props => {
  const inverted = useInvertedScale();

  return (
    <StyledTextArea
      style={inverted}
      aria-label={props.placeholder}
      {...props}
    />
  );
};

const Error = ({ error, ...props }) => {
  const inverted = useInvertedScale();

  return (
    <StyledError style={inverted} {...props}>
      {error}
    </StyledError>
  );
};

const StyledInput = styled(motion.input)`
  height: 38px;

  border-radius: 2px;
  border: 0;
`;

const StyledTextArea = styled(motion.textarea)`
  border-radius: 2px;
  border: 0;

  resize: none;
  height: 96px;
`;

const StyledError = styled(motion.div)`
  margin-top: -12px;
  color: white;
`;

const StyledFields = styled(motion.div)`
  grid-row-gap: inherit;
  ${layout()}
  ${grid}
  
  width: 100%;

  & > * {
    outline: none;
    margin-left: 22px;
    margin-right: 22px;

    ${layout()}
  }
`;
