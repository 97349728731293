import styled, { css } from 'styled-components';
import { columnQuery } from '../../helpers/layout';

const large = css`
  font-size: 36px;
  font-weight: 900;
  line-height: 1.25em;

  @media ${columnQuery(12)} {
    font-size: 44px;
  }
`;

const medium = css`
  font-size: 20px;
  font-weight: 900;
  line-height: 1em;

  @media ${columnQuery(12)} {
    font-size: 24px;
  }
`;
const small = css`
  font-size: 18px;
  font-weight: 900;
  line-height: 1em;

  @media ${columnQuery(12)} {
    font-size: 20px;
  }
`;

const extraSmall = css`
  font-size: 16px;
  //line-height: 22px;
  line-height: 1.5em;
  font-weight: bold;

  @media ${columnQuery(12)} {
    font-size: 18px;
  }
`;

export const titleSize = {
  large,
  medium,
  small,
  extraSmall
};

const Title = styled.div`
  ${props => (props.size ? props.size : '')};
`;

export default Title;
