import styled from 'styled-components';
import { columnQuery } from '../helpers/layout';

export const Paragraph = styled.p`
  font-size: 14px;
  //line-height: 19px;
  line-height: 1.5em;

  //font-weight: bold;

  @media ${columnQuery(12)} {
    font-size: 16px;
    //line-height: 22px;
  }
`;
