import { useHistory } from 'react-router-dom';

const useGoto = callback => {
  const history = useHistory();

  const goto = path => () => {
    history.push(path);

    if (callback) {
      callback(path);
    }
  };

  return goto;
};
export default useGoto;
