import React from 'react';
import styled from 'styled-components';
import colors from '../helpers/colors';

const BackgroundSegment = ({
  height,
  position = 'bottom',
  mirror = false,
  ...props
}) => (
  <StyledBackground
    viewBox="0 0 375 48"
    preserveAspectRatio="none"
    height={height}
    position={position}
    mirror={mirror}
    // filter="url(#dropShadow)"
  >
    {/*<defs>*/}
    {/*  <filter id="dropShadow" x="-100%" y="-100%" width="200%" height="200%">*/}
    {/*    <feGaussianBlur in="SourceAlpha" stdDeviation="10" />*/}
    {/*    /!*<feOffset dx="0" dy="-10" result="offsetBlur" />*!/*/}

    {/*    <feComponentTransfer>*/}
    {/*      <feFuncA type="linear" slope=".2" />*/}
    {/*    </feComponentTransfer>*/}
    {/*    <feMerge>*/}
    {/*      <feMergeNode />*/}
    {/*      <feMergeNode in="SourceGraphic" />*/}
    {/*    </feMerge>*/}
    {/*  </filter>*/}
    {/*</defs>*/}
    <path d="M -25 96 H 400 V 0 L 375 0 L 0 48 L -25 50 z " />
  </StyledBackground>
);

const Background = ({
  mirror = false,
  height = 36,
  top = true,
  bottom = true
}) => {
  return (
    <>
      {top && (
        <BackgroundSegment position="top" height={height} mirror={mirror} />
      )}
      {bottom && (
        <BackgroundSegment position="bottom" mirror={!mirror} height={height} />
      )}
    </>
  );
};

export default Background;

const StyledBackground = styled.svg`
  position: absolute;

  ${props => (props.position === 'top' ? 'top: 0' : 'bottom: 0')};

  left: 0;
  width: 100%;
  height: ${props => props.height}px;

  //z-index: -1;

  transform: scale(
    ${props => (props.mirror ? -1 : 1)},
    ${props => (props.position === 'top' ? -1 : 1)}
  );
  path {
    fill: ${colors.background};
  }

  overflow: visible;
`;
