import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Logo from './logo';
import colors from '../../theme/helpers/colors';
import StyledIcon from '../../theme/icons';
import Bars from '../../theme/icons/assets/regular/bars';
import Menu from './menu';
import ColorSchemeToggle from './color_scheme_toggle';

import { useScroll } from 'react-use-gesture';
import { motion, useMotionValue, useTransform } from 'framer-motion';
import { columnQuery } from '../../theme/helpers/layout';

const Header = () => {
  const [isMenuOpen, setMenuOpen] = useState(false);

  return (
    <>
      <StyledHeader>
        <Logo color="#FFFFFF" />

        <StyledRight>
          <Hamburger onClick={() => setMenuOpen(!isMenuOpen)} />
          <ColorSchemeToggle />
        </StyledRight>
        <Background />
      </StyledHeader>
      <Menu isOpen={isMenuOpen} onClose={() => setMenuOpen(false)} />
    </>
  );
};

export default Header;

const Background = () => {
  const scrollMotionValue = useMotionValue(0);
  const opacity = useTransform(scrollMotionValue, [64, 128], [0, 1]);

  const bind = useScroll(
    ({ xy: [, y] }) => {
      scrollMotionValue.set(y);
    },
    { domTarget: window }
  );
  useEffect(bind, [bind]);

  useEffect(() => {
    const scrollTop =
      window.scrollY ||
      document.body.scrollTop ||
      document.documentElement.scrollTop;
    scrollMotionValue.set(scrollTop);
  }, [scrollMotionValue]);

  return <StyledBackground style={{ opacity }} />;
};

const Hamburger = props => (
  <StyledHamburger {...props}>
    <Bars />
  </StyledHamburger>
);

const StyledHeader = styled.header`
  position: fixed;

  align-self: center;

  z-index: 100;
  max-width: 960px;
  width: 100%;
  height: 96px;

  display: grid;
  grid-template-columns: repeat(var(--gridColumns), minmax(0, 1fr));
  grid-column-gap: var(--gridGutterSize);
  column-gap: var(--gridGutterSize);

  padding: 24px var(--gridMarginSize);

  align-items: center;

  color: ${colors.onPrimary};
`;

const StyledBackground = styled(motion.div)`
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 100%;

  top: 0;
  left: calc(50% - 50vw);
  opacity: 0;

  background: ${colors.primary};
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
`;

const StyledRight = styled.div`
  grid-column: -2;
  justify-self: flex-end;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;

  @media ${columnQuery(12)} {
    & > :first-child {
      display: none;
    }
  }

  @media not all and ${columnQuery(12)} {
    & > :last-child {
      display: none;
    }
  }
`;

const StyledHamburger = styled(StyledIcon)`
  width: 48px;
  height: 48px;
  margin: 0 -8px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 32px;
    height: 32px;
    fill: ${colors.onPrimary};
  }
`;
