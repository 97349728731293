import React from 'react';
import styled from 'styled-components';
import { columnQuery, grid, layout, span } from '../helpers/layout';
import colors from '../helpers/colors';

const Section = ({
  className,
  background,
  backgroundColor,
  children,
  ...props
}) => {
  // add padding to make up for background
  const topPadding = background && background.props.top !== false ? '36px' : 0;
  const bottomPadding =
    background && background.props.bottom !== false ? '36px' : 0;

  return (
    <StyledSection
      backgroundColor={backgroundColor}
      topPadding={topPadding}
      bottomPadding={bottomPadding}
      {...props}
    >
      <StyledInnerSection className={className}>{children}</StyledInnerSection>
      {background}
    </StyledSection>
  );
};

export default Section;

const StyledSection = styled.section`
  background: ${props =>
    props.backgroundColor ||
    `linear-gradient(
    180deg,
    ${props.theme.colors.gradientLight} 0%,
    ${props.theme.colors.gradientDark} 100%
  )`};

  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;

  padding-top: ${props => props.topPadding};
  padding-bottom: ${props => props.bottomPadding};
`;

const StyledInnerSection = styled.div`
  align-self: center;
  max-width: 960px;
  width: 100%;

  z-index: 1;

  color: ${colors.onSurface};

  padding-top: 40px;
  padding-bottom: 40px;

  grid-row-gap: 40px;

  @media ${columnQuery(12)} {
    padding-top: 56px;
    padding-bottom: 56px;

    grid-row-gap: 48px;
  }

  & > * {
    ${layout({ 8: span(6) })};
  }

  ${grid};
`;
