import React from 'react';
import styled from 'styled-components';
import Section from '../../../theme/section';
import colors from '../../../theme/helpers/colors';
import { grid, layout, span } from '../../../theme/helpers/layout';
import PhoneLaptop from '../../../theme/icons/assets/regular/phone_laptop';
import Radar from '../../../theme/icons/assets/regular/radar';
import Mobile from '../../../theme/icons/assets/regular/mobile';
import Title, { titleSize } from '../../../theme/typography/title';
import Service from './service';

const Services = () => {
  return (
    <StyledSection id="services" itemScope itemType="http://schema.org/Service">
      <meta itemProp="serviceType" content="Website Design" />
      <Title size={titleSize.small} as="h2">
        Our Services
      </Title>
      <StyledServiceGrid
        itemProp="hasOfferCatalog"
        itemScope
        itemType="http://schema.org/OfferCatalog"
      >
        <Service
          title="Web Page Design"
          serviceType="Web Page Design and Development"
          icon={<PhoneLaptop />}
        >
          Enchant your customers with custom web page design, tailored for your
          unique brand. You get one opportunity to make a first impression.
        </Service>
        <Service
          title="Mobile Web Apps"
          serviceType="Mobile Web App Design and Development"
          icon={<Mobile />}
        >
          Mobile web apps extend your presence, giving customers a captivating
          experience. Convenience drives adoption, be where your customers are.
        </Service>
        <Service
          title="Search Engine Optimization"
          serviceType="Search Engine Optimization"
          altName="SEO"
          icon={<Radar />}
        >
          Mobile web apps extend your presence, giving customers a captivating
          experience. Convenience drives adoption, be where your customers are.
        </Service>
      </StyledServiceGrid>
    </StyledSection>
  );
};

export default Services;

const StyledSection = styled(Section).attrs(props => ({
  backgroundColor: props.theme.colors.background,
  ...props
}))`
  color: ${colors.onSurface};

  grid-auto-rows: min-content;

  & > ${Title} {
    font-size: 18px;
  }
`;

const StyledServiceGrid = styled.ul`
  grid-row-gap: 36px;

  & > * {
    ${layout({ 12: span(4, 'none') })}
  }

  ${grid}
`;
