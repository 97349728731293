import React from 'react';
import styled from 'styled-components';
import colors from '../../../theme/helpers/colors';
import { columnQuery, layout, span } from '../../../theme/helpers/layout';
import StyledButton from '../../../theme/buttons';
import Section from '../../../theme/section';
import Background from '../../../theme/backgrounds';
import useGoto from '../../../hooks/router_goto';
import { Paragraph } from '../../../theme/typography';
import Title, { titleSize } from '../../../theme/typography/title';

const Hero = () => {
  const goto = useGoto();

  return (
    <StyledSection
      background={<Background top={false} mirror />}
      backgroundColor="transparent"
      id="home"
    >
      <Title size={titleSize.large} as="h1">
        ELEVATE YOUR BUSINESS
      </Title>

      <Paragraph>
        Unique experiences and scalable solutions that drive results to grow
        your business. Get an instant estimate today, and let’s work together to
        accomplish your goals.
      </Paragraph>
      <StyledButton onClick={goto('#pricingTool')}>Get Estimate</StyledButton>
    </StyledSection>
  );
};

export default Hero;

const StyledSection = styled(Section)`
  position: relative;

  grid-auto-rows: min-content;

  color: ${colors.onPrimary};

  padding-top: 40px;
  padding-bottom: 40px;

  @media ${columnQuery(12)} {
    padding-top: 96px;
    padding-bottom: 96px;
  }

  & > ${Title} {
    text-align: center;

    ${layout({ 8: span(6), 12: span(8) })};
  }

  & > p {
    text-align: center;

    ${layout({ 8: span(6), 12: span(8) })};
  }

  & > button {
    ${layout({ any: span(2, 'center', true), 12: span(4, 'center') })};
  }
`;
