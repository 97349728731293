import { useState } from 'react';

const theme = {
  typography: {},
  layout: {
    maxContentWidth: 984
  },
  shadow: {}
};

export const light = {
  ...theme,
  colorScheme: 'light',
  colors: {
    primary: '#8843B7',
    primaryLight: '#CF9DDE',
    primaryDark: '#6d429b',
    gradientLight: '#8843B7',
    gradientDark: '#7526D4',
    secondary: '#D9209F',
    background: '#FFFFFF',
    surface: '#EFEFEF',
    error: '#B0002D',
    onPrimary: '#FFFFFF',
    onSecondary: '#FFFFFF',
    onBackground: '#000000',
    onSurface: '#000000',
    onSurfaceLight: '#4B4B4B',
    onError: '#FFFFFF'
  }
};

export default light;

export const dark = {
  ...theme,
  colorScheme: 'dark',
  colors: {
    primary: '#8012F1',
    primaryLight: '#81427A',
    primaryDark: '#6d429b',
    gradientLight: '#50236E',
    gradientDark: '#34066B',
    secondary: '#D9209F',
    background: '#0f0f1f',
    surface: '#333333',
    error: '#B0002D',
    onPrimary: '#FFFFFF',
    onSecondary: '#FFFFFF',
    onBackground: '#FFFFFF',
    onSurface: '#FFFFFF',
    onSurfaceLight: '#4B4B4B',
    onError: '#FFFFFF'
  }
};

export const getColorScheme = (scheme = 'light') => {
  return { theme: scheme === 'light' ? light : dark, scheme };
};

export const useColorScheme = (initial = 'light') => {
  const [scheme, setScheme] = useState(initial);

  const toggleScheme = () => {
    setScheme(scheme === 'light' ? 'dark' : 'light');
  };

  return { ...getColorScheme(scheme), toggleScheme };
};
