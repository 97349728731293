import theme from '../../';

const generateColorHelpers = () => {
  let result = {};
  for (const key in theme.colors) {
    if (theme.colors.hasOwnProperty(key)) {
      result[key] = ({ theme }) => theme.colors[key];
    }
  }

  return result;
};

const colors = generateColorHelpers();
export default colors;
