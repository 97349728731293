import styled from 'styled-components';
import { motion } from 'framer-motion';
import React from 'react';

const Sun = props => (
  <StyledSun
    viewBox="0 0 512 512"
    {...props}
    variants={{
      unchecked: { opacity: 1 },
      checked: { opacity: 0 }
    }}
  >
    <path d="M502 241l-94-48 33-100c5-14-8-27-22-22l-100 33-47-94a17 17 0 00-31 0l-48 94L93 71c-14-5-27 8-22 22l33 100-94 47a17 17 0 000 32l94 47-33 100c-5 14 8 27 22 22l100-33 47 94a17 17 0 0031 0l48-94 100 33c14 5 27-8 22-22l-33-100 94-47a17 17 0 000-32zM347 347c-50 49-132 49-181 0a128 128 0 010-181c49-50 131-50 181 0a128 128 0 010 181z" />
    <path d="M352 256a96 96 0 11-96-96 96 96 0 0196 96z" />{' '}
  </StyledSun>
);

export default Sun;

const StyledSun = styled(motion.svg)`
  position: absolute;

  width: 14px;

  right: 10px;

  bottom: 50%;
  path {
    &:first-child {
      fill: #ffbd00;
      opacity: 40%;
    }
    &:nth-child(2) {
      fill: #ffbd00;
    }
  }
`;
