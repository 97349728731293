import styled from 'styled-components';
import { motion } from 'framer-motion';
import React from 'react';

const StyledClouds = styled(motion.div)`
  position: absolute;
  width: 50%;
  height: 100%;

  & > svg {
    position: absolute;
    width: 10px;
    height: 7px;

    &:first-child {
      //left: calc(50% - 26px);

      top: 50%;
    }

    &:last-child {
      //left: calc(50% - 26px);

      top: 20px;
    }
  }
`;

const Cloud = props => (
  <motion.svg viewBox="0 0 640 512" {...props} preserveAspectRatio="none">
    <path d="M640 352a128 128 0 01-128 128H144a144 144 0 01-48-280v-8a160 160 0 01299-80 96 96 0 01149 80 97 97 0 01-6 35 128 128 0 01102 125z" />
  </motion.svg>
);

const Clouds = () => {
  const transition = { duration: 0.2 };
  return (
    <StyledClouds
      variants={{
        unchecked: {
          x: '100%',
          fill: '#FFFFFF'
        },
        checked: {
          x: '0%',
          fill: '#a1a0a0'
        }
      }}
      transition={transition}
    >
      <Cloud
        variants={{ unchecked: { x: '16px' }, checked: { x: '4px' } }}
        transition={transition}
      />
      <Cloud
        variants={{ unchecked: { x: '6px' }, checked: { x: '14px' } }}
        transition={transition}
      />
    </StyledClouds>
  );
};

export default Clouds;
