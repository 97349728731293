import React from 'react';
import styled from 'styled-components';
import { grid, layout } from '../../../../../theme/helpers/layout';
import { motion, useInvertedScale } from 'framer-motion';
import Price from './price';
import Label from './label';
import ControlContainer from './control';

const Option = ({
  label,
  price,
  fullWidth = false,
  Control,
  onChange,
  value,
  name,
  showControl
}) => {
  const hasControl = Control && showControl;
  const inverted = useInvertedScale();

  return (
    <StyledOption
      fullWidth={fullWidth}
      hasControl={hasControl}
      style={inverted}
    >
      <Label>{label}</Label>
      <Price>{price}</Price>

      {hasControl && (
        <ControlContainer>
          <Control
            name={name}
            onChange={onChange}
            value={value}
            aria-label={label}
          />
        </ControlContainer>
      )}
    </StyledOption>
  );
};

export default Option;

const StyledOption = styled(motion.div)`
  grid-row-gap: 12px;

  & > :nth-child(3) {
    grid-column: ${props => (props.fullWidth ? '1 / -1' : 'span 2')};
    margin-right: ${props => (props.fullWidth ? '22px' : 0)};
  }

  & > :nth-child(2) {
    grid-row: ${props => (props.fullWidth || !props.hasControl ? 1 : 2)};
  }

  align-items: center;

  ${grid}
  ${layout()}
`;
