import React from 'react';
import styled from 'styled-components';

const Logo = ({ color }) => (
  <StyledLogo>
    <svg viewBox="0 0 65 45">
      <defs>
        <linearGradient
          x1="-22.82%"
          y1="82.19%"
          x2="191.88%"
          y2="-55.24%"
          id="a"
        >
          <stop stopColor="#662D91" offset="0%" />
          <stop stopColor="#711E92" offset="40.76%" />
          <stop stopColor="#870094" offset="100%" />
        </linearGradient>
        <linearGradient x1="3.54%" y1="66.97%" x2="146.94%" y2="11.3%" id="b">
          <stop stopColor="#662D91" offset="0%" />
          <stop stopColor="#711E92" offset="40.76%" />
          <stop stopColor="#870094" offset="100%" />
        </linearGradient>
        <linearGradient x1="-17.43%" y1="109.12%" x2="80.23%" y2="26.4%" id="c">
          <stop stopColor="#662D91" offset="0%" />
          <stop stopColor="#711E92" offset="40.76%" />
          <stop stopColor="#870094" offset="100%" />
        </linearGradient>
      </defs>

      <g fillRule="nonzero">
        <path
          d="M1.11 31c-.02 0-1.04-.01-1.1-1.04L0 29.9v-4.48c-.05-5.16 1.64-9.28 5.02-12.26 6.6-5.81 18.52-5.15 18.96-5.12.29.01.56.14.75.35.19.2.28.48.27.76a1.08 1.08 0 01-1.18.96c-.1 0-11.42-.61-17.3 4.58-2.85 2.5-4.3 6.02-4.32 10.45v4.81C2.16 30.98 1.12 31 1.11 31z"
          fill={color || 'url(#a)'}
        />
        <path
          d="M37.93 45h-4.46a3.96 3.96 0 01-4.05-3.85v-2.3a5 5 0 00-4.07-4.83c-.68-.15-1.38-.24-2.08-.28-.8 0-1.59.1-2.36.28a4.05 4.05 0 00-3.25 3.89V41a4.11 4.11 0 01-4.2 3.99H9.67C7.1 45 5 43 5 40.55V25.01c.01-.56.48-1.01 1.06-1.01.58 0 1.05.45 1.07 1.01v15.54a2.5 2.5 0 002.55 2.43h3.77A2.03 2.03 0 0015.53 41v-3.1a6.04 6.04 0 014.91-5.85c.95-.22 1.92-.33 2.9-.33.82.04 1.65.15 2.46.33a7.07 7.07 0 015.75 6.8v2.3a1.88 1.88 0 001.92 1.83h4.46a1.9 1.9 0 001.94-1.85v-4.2c.02-.55.49-1 1.07-1 .58 0 1.05.45 1.06 1v4.2A3.98 3.98 0 0137.93 45z"
          fill={color || 'url(#b)'}
        />
        <path
          d="M63.64 42.52a11.6 11.6 0 01-9.22 2.3c-3.85-.63-6.55-2.37-8.02-5.16-1.13-2.12-1.5-4.89-1.09-7.98a.9.9 0 011.8.23c-.6 4.41.25 9.92 7.61 11.14a9.7 9.7 0 007.8-1.95 1.73 1.73 0 00-.38-2.93L62 38.1a1.8 1.8 0 00-1.83.25 6.33 6.33 0 01-5.45 1.16 3.45 3.45 0 01-2.33-1.71c-1.1-1.87-2-6.13 2.85-21.49 1-3.15.78-5.75-.63-7.74-1.88-2.63-5.5-3.73-7.92-4.18.6.8 1.08 1.7 1.44 2.65a.9.9 0 01-.03.7.91.91 0 01-1.67-.07 8.95 8.95 0 00-2.8-3.87 9.21 9.21 0 00-11.45-.01 8.81 8.81 0 00.35 14.12.89.89 0 01.2 1.26.91.91 0 01-1.27.2A10.58 10.58 0 0127 10.98a10.6 10.6 0 014.03-8.6c4-3.14 9.58-3.18 13.6-.08 1.59.1 8.31.84 11.45 5.23 1.76 2.46 2.05 5.6.88 9.32-3.43 10.86-4.44 17.6-3 20.04.23.45.65.76 1.13.86 1.53.32 2.85.05 3.94-.8a3.62 3.62 0 013.7-.5l.15.07a3.51 3.51 0 01.76 6z"
          fill={color || 'url(#c)'}
        />
      </g>
    </svg>
  </StyledLogo>
);

export default Logo;

const StyledLogo = styled.div`
  height: 48px;
  cursor: pointer;

  svg {
    width: 100%;
    height: 100%;
  }
`;
