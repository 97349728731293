import colors from '../../../../theme/helpers/colors';
import React from 'react';
import {
  StyledBody,
  StyledCard,
  StyledContent,
  StyledIcon,
  StyledTitle
} from '../../../../theme/card';

const Service = ({ icon, title, serviceType, altName, children }) => {
  return (
    <StyledCard
      as="li"
      itemProp="itemListElement"
      itemScope
      itemType="http://schema.org/Offer"
    >
      <StyledIcon color1={colors.primaryLight} color2={colors.primary}>
        {icon}
      </StyledIcon>
      <StyledContent
        itemProp="itemOffered"
        itemScope
        itemType="http://schema.org/Service"
      >
        <meta itemProp="serviceType" content={serviceType} />

        <StyledTitle itempProp="name">{title}</StyledTitle>
        {altName && <meta itemProp="alternateName" content={altName} />}
        <StyledBody itemProp="description">{children}</StyledBody>
      </StyledContent>
    </StyledCard>
  );
};

export default Service;
