import React from 'react';
import styled from 'styled-components';

const MenuItem = ({ children, ...props }) => {
  return (
    <StyledContainer {...props} role="link">
      <StyledItem>{children}</StyledItem>
    </StyledContainer>
  );
};

export default MenuItem;

const StyledItem = styled.div`
  font-size: 20px;
  font-weight: bold;
  transition: transform 200ms;
`;

const StyledContainer = styled.div`
  height: 48px;

  display: flex;
  align-items: center;

  cursor: pointer;

  &:hover > ${StyledItem} {
    transform: scale(1.1);
  }

  user-select: none;
`;
