import React from 'react';

import { ThemeProvider } from 'styled-components';
import { useColorScheme } from './theme';
import Body from './sections/body';
import Header from './sections/header';
import Home from './screens/home';
import Footer from './sections/footer';
import useRouterScroll from './hooks/router_scroll';
import { AppContext } from './context';
import { useTracker } from './utility/analytics';
import Background from './background';

const App = () => {
  useRouterScroll();
  useTracker();

  const { scheme, theme, toggleScheme } = useColorScheme('light');

  return (
    <AppContext.Provider value={{ theme, scheme, toggleScheme }}>
      <ThemeProvider theme={theme}>
        <Header />
        <Body>
          <Home />
        </Body>
        <Footer />
        <Background />
      </ThemeProvider>
    </AppContext.Provider>
  );
};

export default App;
