import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga';

ReactGA.initialize('UA-158668924-1');

export const useTracker = () => {
  const { pathname, search, hash } = useLocation();

  useEffect(() => {
    ReactGA.pageview(pathname + search + hash);
  }, [pathname, search, hash]);
};
