import StyledButton from '../../../../theme/buttons';
import React from 'react';
import ReactGA from 'react-ga';
import styled from 'styled-components';
import { grid, layout, span } from '../../../../theme/helpers/layout';
import colors from '../../../../theme/helpers/colors';
import { AnimatePresence, motion } from 'framer-motion';

const Controls = ({ page, onSubmit, setPage, resetValues, spring }) => {
  return (
    <StyledControls>
      <AnimatePresence>
        {page === 1 && (
          <StyledResetButton
            onClick={resetValues}
            type="button"
            key="reset"
            initial={{ opacity: 0, x: -100 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -100 }}
            transition={{ x: spring }}
            positionTransition={spring}
            show={page === 1}
          >
            Reset
          </StyledResetButton>
        )}

        <StyledSubmitButton
          onClick={() => {
            if (page === 0) {
              ReactGA.event({
                category: 'Pricing Tool',
                action: 'Clicked Next'
              });

              setPage(1);
            } else {
              ReactGA.event({
                category: 'Pricing Tool',
                action: 'Clicked Submit'
              });
              onSubmit();
            }
          }}
          type="button"
          page={page}
          key="submit"
          exit={{ opacity: 0 }}
          positionTransition={spring}
        >
          {page === 1 ? 'Submit' : 'Get Started Now'}
        </StyledSubmitButton>
      </AnimatePresence>
    </StyledControls>
  );
};

export default Controls;

const StyledControls = styled(motion.div)`
  & > * {
    grid-row: 1;
  }
  ${layout()}
  ${grid}
`;

const StyledResetButton = styled(StyledButton)`
  ${layout({ any: span(2, 'left'), 12: span(3, 'left') })};
  color: ${colors.secondary};
  background: ${colors.onSecondary};

  ${props => (props.show ? '' : 'display: none')};

  margin-left: 22px;
  align-self: flex-end;
`;
const StyledSubmitButton = styled(StyledButton)`
  ${props =>
    props.page === 0
      ? layout({
          any: span(2, 'center', true)
        })
      : layout({
          any: span(2, 'right'),
          12: span(3, 'right')
        })};

  margin-right: ${props => (props.page === 0 ? 0 : '22px')};

  align-self: flex-end;
`;
