import React from 'react';
import styled from 'styled-components';
import Section from '../../../theme/section';
import Card from '../../../theme/card';
import colors from '../../../theme/helpers/colors';
import { grid, layout, span } from '../../../theme/helpers/layout';

import Columns from '../../../theme/icons/assets/regular/columns';
import Keyboard from '../../../theme/icons/assets/regular/keyboard';
import RocketLaunch from '../../../theme/icons/assets/regular/rocket_launch';
import Tasks from '../../../theme/icons/assets/regular/tasks';
import Title, { titleSize } from '../../../theme/typography/title';

const Process = () => {
  return (
    <StyledSection id="process">
      <Title size={titleSize.small} as="h2">
        You’ll Love Our Process
      </Title>
      <StyledCardGrid>
        <Card title="Define" icon={<Tasks />} as="li">
          Our consultants work hand-in-hand to make sure your unique goals are
          realized.
        </Card>
        <Card title="Design" icon={<Columns />} as="li">
          We design a bespoke solution and make sure you are 100% delighted
          before the first line of code is written.
        </Card>
        <Card title="Develop" icon={<Keyboard />} as="li">
          Expert engineers implement your design, keeping you updated at every
          step.
        </Card>
        <Card title="Deploy" icon={<RocketLaunch />} as="li">
          We release your solution.
        </Card>
      </StyledCardGrid>
    </StyledSection>
  );
};

export default Process;

const StyledSection = styled(Section).attrs(props => ({
  backgroundColor: props.theme.colors.background
}))`
  color: ${colors.onSurface};

  grid-auto-rows: min-content;
`;

const StyledCardGrid = styled.ol`
  grid-row-gap: 36px;

  & > * {
    ${layout({ 12: span(6, 'none') })}
  }

  ${grid}
`;
