import React from 'react';
import styled from 'styled-components';
import { layout } from '../../../../theme/helpers/layout';
import StyledIcon from '../../../../theme/icons';
import CheckCircle from '../../../../theme/icons/assets/regular/check_circle';
import colors from '../../../../theme/helpers/colors';
import { motion } from 'framer-motion';

const Complete = () => {
  return (
    <StyledComplete initial={{ opacity: 0 }} animate={{ opacity: 1 }}>
      <StyledIcon
        color1={'#00000044'}
        color2={colors.onPrimary}
        width="180px"
        initial={{ scale: 0.5 }}
        animate={{ scale: 1 }}
        transition={{ type: 'spring', stiffness: 150 }}
      >
        <CheckCircle />
      </StyledIcon>
      Thank You! One of our experts will reach out to you within 24 - 48 hours
      to talk with you about your project needs.
    </StyledComplete>
  );
};

export default Complete;

const StyledComplete = styled(motion.div)`
  padding: 0 22px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  line-height: 19px;

  & > :first-child {
    margin-bottom: 25px;
  }

  ${layout()}
`;
