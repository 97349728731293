import React from 'react';
import styled from 'styled-components';
import colors from '../../theme/helpers/colors';

const Footer = () => {
  return <StyledFooter>Copyright 2020 New Logic Design LLC</StyledFooter>;
};

export default Footer;

const StyledFooter = styled.footer`
  position: relative;

  align-self: center;

  width: 100%;
  height: 64px;

  font-weight: bold;
  font-size: 12px;

  color: ${colors.onPrimary};
  background: ${colors.gradientDark};

  display: flex;
  align-items: center;
  justify-content: center;
`;
