import React, { useState } from 'react';
import { useFormik } from 'formik';
import { object, string, number, boolean } from 'yup';

import styled from 'styled-components';
import Section from '../../../theme/section';
import colors from '../../../theme/helpers/colors';
import { grid, layout, span } from '../../../theme/helpers/layout';
import { motion } from 'framer-motion';
import Options from './options';
import Fields from './fields';
import Controls from './controls';
import Complete from './complete';
import getPricing from './options/prices';
import { Paragraph } from '../../../theme/typography';
import Title, { titleSize } from '../../../theme/typography/title';

const fieldValues = {
  name: '',
  email: '',
  message: '',
  pages: 1,
  seo: false,
  copyWriting: false,
  customForms: false,
  eCommerce: false
};
const spring = { type: 'spring', stiffness: 200, damping: 30 };

const validationSchema = object().shape({
  name: string().required('required'),
  email: string()
    .email('invalid')
    .required('required'),
  message: string(),
  pages: number().integer(),
  seo: boolean(),
  copyWriting: boolean(),
  customForms: boolean(),
  eCommerce: boolean()
});

const PricingTool = () => {
  const [page, setPage] = useState(0);

  const formik = useFormik({
    initialValues: {
      ...fieldValues
    },
    validationSchema,
    onSubmit: async values => {
      setPage(page + 1);
      const pricing = getPricing(values);

      try {
        await fetch('/.netlify/functions/submit_quote', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json;charset=utf-8'
          },
          body: JSON.stringify({
            total: '$' + pricing.total || pricing,
            ...values
          })
        });
      } catch (error) {
        console.error(error);
      }
    }
  });

  const resetValues = () => {
    setPage(0);
    formik.resetForm(fieldValues);
  };

  return (
    <StyledSection id="pricingTool">
      <Title size={titleSize.medium} as="h2">
        Pricing Tool Estimator
      </Title>
      <Paragraph>
        Get an <b>Instant Estimate today</b> using our Pricing Tool Calculator
        and one of our experts will reach out to you shortly.
      </Paragraph>
      <StyledContainer>
        {page !== 2 && (
          <Options
            page={page}
            showControls={page === 0}
            onChange={formik.handleChange}
            values={formik.values}
            spring={spring}
          />
        )}

        {page === 1 && (
          <Fields
            onChange={formik.handleChange}
            values={formik.values}
            errors={formik.errors}
            spring={spring}
          />
        )}

        {page !== 2 && (
          <Controls
            page={page}
            onSubmit={formik.handleSubmit}
            spring={spring}
            resetValues={resetValues}
            setPage={setPage}
          />
        )}

        {page === 2 && <Complete />}
      </StyledContainer>
    </StyledSection>
  );
};

export default PricingTool;

const StyledSection = styled(Section).attrs(props => ({
  backgroundColor: props.theme.colors.background
}))`
  color: ${colors.onSurface};

  & > ${Title} {
    text-align: center;
  }

  p {
    text-align: center;
  }

  b {
    font-weight: bold;
  }

  hr {
    grid-column: 1 / -1;

    margin: 0 22px;

    height: 1px;
    align-self: center;
  }
`;

const StyledContainer = styled(motion.form)`
  color: ${colors.onPrimary};

  background-image: linear-gradient(
    180deg,
    ${colors.gradientLight} 0%,
    ${colors.gradientDark} 100%
  );
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  border-radius: 31px;

  min-height: 528px;

  padding: 22px 0;

  grid-row-gap: 18px;
  //grid-auto-rows: 1fr;

  ${grid}

  ${layout({ any: span(4), 12: span(6) })};
`;
