import React from 'react';

const Mobile = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
    <path d="M0 384v80a48 48 0 0 0 48 48h224a48 48 0 0 0 48-48v-80zm160 96a32 32 0 1 1 32-32 32 32 0 0 1-32 32z" />
    <path d="M0 384V48A48 48 0 0 1 48 0h224a48 48 0 0 1 48 48v336z" />
  </svg>
);

export default Mobile;
