import styled from 'styled-components';
import { motion } from 'framer-motion';
import React from 'react';

const ControlContainer = ({ children }) => {
  return (
    <StyledControlContainer initial={{ opacity: 1 }} animate={{ opacity: 1 }}>
      {children}
    </StyledControlContainer>
  );
};

export default ControlContainer;

const StyledControlContainer = styled(motion.div)`
  grid-row: 2;
  margin-left: 22px;

  display: flex;
  & > * {
    width: 100%;
  }
`;
