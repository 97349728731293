import React from 'react';
import styled from 'styled-components';
import Section from '../../../theme/section';
import colors from '../../../theme/helpers/colors';
import Background from '../../../theme/backgrounds';
import { Paragraph } from '../../../theme/typography';
import Title, { titleSize } from '../../../theme/typography/title';

const About = () => {
  return (
    <StyledSection background={<Background mirror />} id="about">
      <StyledSubsection>
        <Title size={titleSize.small} as="h2">
          About New Logic Design
        </Title>
        <Paragraph>
          We create innovative technical solutions for businesses throughout the
          United States. We tailor our approach for companies of every size,
          whether you are a sole proprietor, small business, or multinational
          corporation. Our comprehensive range of services includes web design
          and development, app design and development, copywritting and search
          engine optimization (SEO). We focus on delivering value by providing
          expertise to help your business grow. From first idea to final
          product, we stand by you every step of the way.
        </Paragraph>
      </StyledSubsection>
      <StyledSubsection>
        <Title size={titleSize.small} as="h2">
          Our Mission
        </Title>
        <Paragraph>
          Our approach is to make you feel included in each project as we work
          together to solve your business needs. At New Logic Design LLC, our
          goal is to help you successfully grow your business and engage your
          customers. Using the latest technologies and trends, we ensure you get
          a product that is 100% unique. Let us worry about the technology and
          let you get back to doing what you do best.
        </Paragraph>
      </StyledSubsection>
    </StyledSection>
  );
};

export default About;

const StyledSection = styled(Section)`
  color: ${colors.onPrimary};

  grid-auto-rows: min-content;
`;

const StyledSubsection = styled.div`
  & > ${Title} {
    margin-bottom: 1em;
  }
  & > p {
    font-weight: bold;
  }
`;
