import { useAppContext } from '../context';
import dayImage from '../assets/day.svg';
import nightImage from '../assets/night.svg';
import styled from 'styled-components';
import React from 'react';

const Background = () => {
  const { scheme } = useAppContext();

  return (
    <StyledBackground image={scheme === 'light' ? dayImage : nightImage} />
  );
};

export default Background;

const StyledBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 800px;

  background: linear-gradient(180deg, #6600acd6 0%, #581f9dd6 100%),
    url(${props => props.image}) no-repeat center center / cover;

  z-index: -1000;
`;
