import React, { useContext } from 'react';
import styled from 'styled-components';
import colors from '../../helpers/colors';
import { motion } from 'framer-motion';
import { ThemeContext } from 'styled-components';

const Toggle = ({ value, name, onChange, buttonSize = 24, ...props }) => {
  const theme = useContext(ThemeContext);

  const toggle = () => {
    onChange({
      target: {
        name: name,
        type: 'checkbox',
        checked: !value
      }
    });
  };

  return (
    <StyledToggle
      onClick={toggle}
      buttonSize={buttonSize}
      {...props}
      aria-checked={value}
      role="switch"
      animate={value ? 'checked' : 'unchecked'}
    >
      <StyledSlideContainer
        variants={{ checked: { x: '100%' }, unchecked: { x: '0%' } }}
      >
        <StyledSlider />
      </StyledSlideContainer>
      <StyledBackground
        variants={{
          checked: { backgroundColor: '#ffa6fa' },
          unchecked: { backgroundColor: theme.colors.onPrimary }
        }}
      />
    </StyledToggle>
  );
};

export default Toggle;

const StyledBackground = styled(motion.div)`
  background: ${colors.onPrimary};
  width: 100%;
  top: 4px;
  bottom: 4px;
  left: 0;

  position: absolute;

  z-index: -1;
  border-radius: 13px;
`;

const StyledSlideContainer = styled(motion.div)`
  height: 100%;
  position: relative;
`;

const StyledSlider = styled(motion.div)`
  background: ${colors.secondary};

  border-radius: 50%;
  position: absolute;
`;

const StyledToggle = styled(motion.div)`
  height: ${props => props.buttonSize}px;

  max-width: 48px;

  position: relative;

  z-index: 1;

  & > ${StyledSlideContainer} {
    width: calc(100% - ${props => props.buttonSize}px);
    & > ${StyledSlider} {
      width: ${props => props.buttonSize}px;
      height: ${props => props.buttonSize}px;
    }
  }
`;
